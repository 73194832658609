/**
 * @copyright 2020 @ DigiNet
 * @author HuyTran
 * @create 10/14/2020
 * @Example
 */

export const types = {
    W29F2100_GET_NOTE: "W29F2100_GET_NOTE",
    W29F2100_GET_MONTH_PAYROLL: "W29F2100_GET_MONTH_PAYROLL",
    W29F2100_GET_DETAIL_WORKDAY: "W29F2100_GET_DETAIL_WORKDAY",
};

//get-date-by-tran
export function getNote(params, cb) {
    return {
        type: types.W29F2100_GET_NOTE,
        params,
        cb
    }
}
//get-month-payroll
export function getMonthPayroll(params, cb) {
    return {
        type: types.W29F2100_GET_MONTH_PAYROLL,
        params,
        cb
    }
}
//get-detail-workday
export function getDetailWorkday(params, cb) {
    return {
        type: types.W29F2100_GET_DETAIL_WORKDAY,
        params,
        cb
    }
}