/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/5/2019
 * @Example
 */

import { types } from "./W29F2005_actions";

const initialState = {
  timeKeepingList: [],
  getCboHandOver: [],
  getCboProjects: [],
  getDateByTran: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case types.W29F2005_GET_TIMEKEEPINGLIST_SUCCESS: {
      return { ...state, timeKeepingList: action.data };
    }
    case types.W29F2005_GET_CBO_HANDOVER_SUCCESS:
      return {
        ...state,
        getCboHandOver: action.data
      };
    case types.W29F2005_GET_CBO_PROJECTS_SUCCESS:
      return {
        ...state,
        getCboProjects: action.data
      };
    case types.W29F2005_GET_DATE_BY_TRAN_SUCCESS:
      return {
        ...state,
        getDateByTran: action.data
      };
    default: {
      return state;
    }
  }
}
