/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/29/2020
 * @Example
 */

import {types} from "./W29F2000_actions";

const initialState = {
    getCboShifts: [],
    getWorkingDayType: [],
    getDataGrid: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W29F2000_GET_CBO_SHIFT_SUCCESS:
            return {
                ...state,
                getCboShifts: action.data
            };
        case types.W29F2000_GET_WORKING_DAY_TYPE_SUCCESS:
            return {
                ...state,
                getWorkingDayType: action.data
            };
        case types.W29F2000_GET_DATAGRID_SUCCESS:
            return {
                ...state,
                getDataGrid: action.data
            };
        default: {
            return state;
        }
    }
}
