/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/2/2019
 * @Example
 */

export const types = {
  W29F2005_GET_TIMEKEEPINGLIST: "W29F2005_GET_TIMEKEEPINGLIST",
  W29F2005_GET_TIMEKEEPINGLIST_SUCCESS: "W29F2005_GET_TIMEKEEPINGLIST_SUCCESS",
  W29F2005_DELETE_ATTENDANCEVOUCHER: "W29F2005_DELETE_ATTENDANCEVOUCHER",
  W29F2005_DELETE_ATTENDANCEVOUCHER_SUCCESS:"W29F2005_DELETE_ATTENDANCEVOUCHER_SUCCESS",
  W29F2005_GET_CBO_HANDOVER:"W29F2005_GET_CBO_HANDOVER",
  W29F2005_GET_CBO_HANDOVER_SUCCESS:"W29F2005_GET_CBO_HANDOVER_SUCCESS",
  W29F2005_GET_CBO_PROJECTS:"W29F2005_GET_CBO_PROJECTS",
  W29F2005_GET_CBO_PROJECTS_SUCCESS:"W29F2005_GET_CBO_PROJECTS_SUCCESS",
  W29F2005_GET_DATE_BY_TRAN:"W29F2005_GET_DATE_BY_TRAN",
  W29F2005_GET_DATE_BY_TRAN_SUCCESS: "W29F2005_GET_DATE_BY_TRAN_SUCCESS",
  W29F2005_CHECK_EDIT_AND_DELETE:"W29F2005_CHECK_EDIT_AND_DELETE"
};

export function getTimeKeepingList(params, cb) {
  return {
    type: types.W29F2005_GET_TIMEKEEPINGLIST,
    params,
    cb
  };
}

export function deleteAttendanceVoucher(params, cb) {
  return {
    type: types.W29F2005_DELETE_ATTENDANCEVOUCHER,
    params,
    cb
  };
}

export function getCboHandOver(params, cb) {
  return {
      type: types.W29F2005_GET_CBO_HANDOVER,
      params,
      cb
  }
}

export function getCboProjects(params, cb) {
  return {
      type: types.W29F2005_GET_CBO_PROJECTS,
      params,
      cb
  }
}

export function getDateByTran(params, cb) {
  return {
      type: types.W29F2005_GET_DATE_BY_TRAN,
      params,
      cb
  }
}



