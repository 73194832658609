import {types} from "./W29F2011_actions";

const initialState = {
    getDataGrid: [],
    getOTTypeName: []

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W29F2011_GET_GRID_DATA_SUCCESS:
            return {
                ...state,
                getDataGrid: action.data
            };
        case types.W29F2011_GET_OT_TYPE_NAME_SUCCESS:
            return {
                ...state,
                getOTTypeName: action.data
            };

        default:
            return state;
    }
}