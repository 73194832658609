/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/14/2020
 * @Example 
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../W2X/W29F3000/W29F3000_actions";
import { delay } from "redux-saga";

export default function W29F3000Sagas() {
    return [
        watchGetCboHandOver(),
        watchGetCboPeriod(),
        watchGetCboReports(),
        watchGetDateData(),
    ];
}

//get cbo period..
export function* getCboReports(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f3000/load-cbo-report-id', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo reports error');
    }

}

export function* watchGetCboReports() {
    while (true) {
        const watcher = yield takeLatest(types.W29F3000_GET_CBO_REPORTS, getCboReports);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo hand over..
export function* getCboHandOver(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2005/get-handover', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo handover error');
    }

}

export function* watchGetCboHandOver() {
    while (true) {
        const watcher = yield takeLatest(types.W29F3000_GET_CBO_HANDOVER, getCboHandOver);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo period..
export function* getCboPeriod(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w75f2010/load-cbo-period', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo period error');
    }

}

export function* watchGetCboPeriod() {
    while (true) {
        const watcher = yield takeLatest(types.W29F3000_GET_CBO_PERIOD, getCboPeriod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo period..
export function* getDateData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f3000/get-date-by-tran', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get date data error');
    }

}

export function* watchGetDateData() {
    while (true) {
        const watcher = yield takeLatest(types.W29F3000_GET_DATE_DATA, getDateData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

