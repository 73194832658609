/**
 * @copyright 2019 @ DigiNet
 * @author TRANGHOANG
 * @create 4/29/2021
 * @Example
 */
export const types = {

    W29F2072_GET_CBO_OTTYPE: "W29F2072_GET_CBO_OTTYPE",
    W29F2072_GET_PARAM_OT_TYPE: "W29F2072_GET_PARAM_OT_TYPE",
    W29F2072_GET_CAPTION: "W29F2072_GET_CAPTION",
    W29F2072_GET_CBO_CHOOSE_GROUP_EMPLOYEE: "W29F2072_GET_CBO_CHOOSE_GROUP_EMPLOYEE",
    W29F2072_GET_CBO_GROUP_EMPLOYEE: "W29F2072_GET_CBO_GROUP_EMPLOYEE",
    W29F2072_GET_FORM: "W29F2072_GET_FORM",
    W29F2072_GET_GRID_EMPLOYEE: "W29F2072_GET_GRID_EMPLPOYEE",
    W29F2072_UPDATE: "W29F2072_UPDATE",
    W29F2072_ADD: "W29F2072_ADD",
    W29F2072_EDIT: "W29F2072_EDIT",
    W29F2072_EMPLOYEE: "W29F2072_EMPLOYEE",
    W29F2072_GET_CAPTION_MASTER: "W29F2072_GET_CAPTION_MASTER",
    W29F2072_LOAD_GRID_SETTING: "W29F2072_LOAD_GRID_SETTING",
    W29F2072_LOAD_GROUP_EMPLOY_VISIBLE: "W29F2072_LOAD_GROUP_EMPLOY_VISIBLE",
    W29F2072_GET_GRID_CAPTION: "W29F2072_GET_GRID_CAPTION",
    W29F2072_LOAD_GROUP_EMPLOYEE: "W29F2072_LOAD_GROUP_EMPLOYEE",
    W29F2072_CANCEL_EMPLOYEE: "W29F2072_CANCEL_EMPLOYEE",
};

export function getCboOtType(params, cb) {
    return {
        type: types.W29F2072_GET_CBO_OTTYPE,
        params,
        cb
    }
}

export function getParamOtType(cb) {
    return {
        type: types.W29F2072_GET_PARAM_OT_TYPE,
        cb
    }
}

export function getCaption(cb) {
    return {
        type: types.W29F2072_GET_CAPTION,
        cb
    }
}

export function getCboChooseGroupEmployee(params,cb) {
    return {
        type: types.W29F2072_GET_CBO_CHOOSE_GROUP_EMPLOYEE,
        params,
        cb
    }
}

export function getCboGroupEmployee(params,cb) {
    return {
        type: types.W29F2072_GET_CBO_GROUP_EMPLOYEE,
        params,
        cb
    }
}

export function getForm(params,cb) {
    return {
        type: types.W29F2072_GET_FORM,
        params,
        cb
    }
}

export function getGridEmployee(params,cb) {
    return {
        type: types.W29F2072_GET_GRID_EMPLOYEE,
        params,
        cb
    }
}


export function update(params,cb) {
    return {
        type: types.W29F2072_UPDATE,
        params,
        cb
    }
}

export function add(params, cb) {
    return {
        type: types.W29F2072_ADD,
        params,
        cb
    }
}

export function edit(params, cb) {
    return {
        type: types.W29F2072_EDIT,
        params,
        cb
    }
}

export function getEmployee(params, cb) {
    return {
        type: types.W29F2072_EMPLOYEE,
        params,
        cb
    }
}

export function cancelEmployee(params, cb) {
    return {
        type: types.W29F2072_CANCEL_EMPLOYEE,
        params,
        cb
    }
}

export function loadGroupEmployee(params, cb) {
    return {
        type: types.W29F2072_LOAD_GROUP_EMPLOYEE,
        params,
        cb
    }
}

export function getGridCaption(cb) {
    return {
        type: types.W29F2072_GET_GRID_CAPTION,
        cb
    }
}

export function loadGroupEmployeeVisible(params, cb) {
    return {
        type: types.W29F2072_LOAD_GROUP_EMPLOY_VISIBLE,
        params,
        cb
    }
}

export function loadGridSetting(cb) {
    return {
        type: types.W29F2072_LOAD_GRID_SETTING,
        cb
    }
}

export function getCaptionMaster(cb) {
    return {
        type: types.W29F2072_GET_CAPTION_MASTER,
        cb
    }
}


// W29F2072_GET_CAPTION_MASTER: "W29F2072_GET_CAPTION_MASTER",
