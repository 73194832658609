/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/2/2019
 * @Example
 */

import Api from "../../../services/api";
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "./W29F2000_actions";
import {delay} from "redux-saga";

export default function W29F2000Sagas() {
    return [
        watchGetCboShifts(),
        watchGetWorkingDayType(),
        watchGetDataGrid(),
        watchSaveData(),
        watchGetCboGroupEmployee()
    ];
}

export function* getCboShifts(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2000/list-of-shift", data.params);
        if (res && res.data) {
            yield put({
                type: types.W29F2000_GET_CBO_SHIFT_SUCCESS,
                data: res.data
            });
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
    }
}

export function* watchGetCboShifts() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2000_GET_CBO_SHIFT,
            getCboShifts
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

/**
 * Get working day type
 * @param data
 * @returns data API
 */
export function* getWorkingDayType(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w29f2000/load-work-date-type");
        if (res && res.data) {
            yield put({
                type: types.W29F2000_GET_WORKING_DAY_TYPE_SUCCESS,
                data: res.data
            });
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
    }
}

export function* watchGetWorkingDayType() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2000_GET_WORKING_DAY_TYPE,
            getWorkingDayType
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

/**
 * Get data of grid
 * @param data
 * @returns data API
 */
export function* getDataGrid(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2000/load-grid", data.params);
        if (res && res.data) {
            yield put({
                type: types.W29F2000_GET_DATAGRID_SUCCESS,
                data: res.data
            });
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2000_GET_DATAGRID,
            getDataGrid
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

/**
 * Save data of grid
 * @param data
 * @returns data API
 */
export function* saveData(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2000/save", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("w29f2000 save data error", e);
    }
}

export function* watchSaveData() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2000_SAVE_DATA,
            saveData
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

/**
 * Get cbo group employee
 * @param data
 * @returns data API
 */
export function* getCboGroupEmployee(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/standard/load-cbo-group-employee", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("w29f2000 get cbo group employee error", e);
    }
}

export function* watchGetCboGroupEmployee() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2000_GET_CBO_GROUP_EMPLOYEE,
            getCboGroupEmployee
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
