/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 2/24/2021
 * @Example 
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2056_actions";
import { delay } from "redux-saga";

export default function W29F2056Sagas() {
    return [
        watchGetOtHour(),
        watchSaveData(),
    ];
}

export function* getOtHour(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2056/load-ot-hour', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get OtHour error');
    }

}

export function* watchGetOtHour() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2056_GET_OT_HOUR, getOtHour);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveData(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w29f2056/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save data error',e);
    }

}

export function* watchSaveData() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2056_SAVE_DATA, saveData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}