/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 6/30/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2012_actions";
import { delay } from "redux-saga";

export default function W29F2012Sagas() {
    return [watchGetForm(), watchGetCboOtType()];
}

export function* getCboOtType(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2012/load-ottype", data.params);
        if (response && response.data) {
            yield put({ type: types.GET_CBO_OTT_TYPE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get Ott Type error");
    }
}

export function* watchGetCboOtType() {
    while (true) {
        const watcher = yield takeLatest(types.GET_CBO_OTT_TYPE, getCboOtType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2012/load-form", data.params);
        if (response && response.data) {
            yield put({ type: types.GET_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
