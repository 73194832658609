
import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../W29F2030/W29F2030_actions";
import { delay } from "redux-saga";

export default function W29F2030Sagas() {
    return [
        watchGetListOfShift(),
        watchGetDataGrid(),
        watchGetDataFormPopup(),
        watchSave(),

    ];
}


//get list of shift
export function* getListOfShift(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2030/load-shift');
        if (response && response.data) {
            yield put({ type: types.W29F2030_LIST_OF_SHIFT_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list of shift error',e);
    }

}

export function* watchGetListOfShift() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2030_LIST_OF_SHIFT, getListOfShift);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get load form grid
export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2030/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get load data grid error',e);
    }

}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2030_GET_DATA_GRID, getDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


//get load form grid
export function* getDataFormPopup(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2031/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get load data form popup error',e);
    }

}

export function* watchGetDataFormPopup() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2030_GET_DATA_FORM_POPUP, getDataFormPopup);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


///save
export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2031/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save data error',e);
    }

}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2030_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

