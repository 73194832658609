export const types = {
    GET_FORM_GRID: "GET_FORM_GRID",
    DELETE_DATA: "DELETE_DATA",
    GET_FORM_GRID_SUCCESS: "GET_FORM_GRID_SUCCESS",
    DELETE_ACT_OT_HOUR:"DELETE_ACT_OT_HOUR",
};

export function getFormGrid(params, cb) {
    return {
        type: types.GET_FORM_GRID,
        params,
        cb,
    };
}

export function deleteData(params, cb) {
    return {
        type: types.DELETE_DATA,
        params,
        cb,
    };
}
export function deleteActOTHour(params, cb) {
    return {
        type: types.DELETE_ACT_OT_HOUR,
        params,
        cb,
    };
}
