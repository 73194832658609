/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 2/24/2021
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest, takeEvery } from "redux-saga/effects";
import { types } from "./W29F2055_actions";
import { delay } from "redux-saga";

export default function W29F2055Sagas() {
    return [
        watchGetCboOtType(),
        watchGetParamOtType(),
        watchGetCaption(),
        watchGetCboChooseGroupEmployee(),
        watchGetCboGroupEmployee(),
        watchGetForm(),
        watchUpdate(),
        watchAdd(),
        watchEdit(),
        WatchGetEmployee(),
        watchCancelData(),
    ];
}

export function* getCboOtType(data) {
    try {
        yield delay(300);

        const response = yield Api.put('/w29f2012/load-ottype', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get OtType error');
    }

}

export function* watchGetCboOtType() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_GET_CBO_OTTYPE, getCboOtType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2056/load-caption');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get caption error');
    }

}

export function* watchGetCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_GET_CAPTION, getCaption);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboChooseGroupEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2055/choose-group-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Cbo Choose Group Employee error');
    }

}

export function* watchGetCboChooseGroupEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_GET_CBO_CHOOSE_GROUP_EMPLOYEE, getCboChooseGroupEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboGroupEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-group-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Cbo Group Employee error');
    }

}

export function* watchGetCboGroupEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_GET_CBO_GROUP_EMPLOYEE, getCboGroupEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form error');
    }

}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeEvery(types.W29F2055_GET_FORM, getForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getParamOtType(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2011/get-ot-type');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Param OtType error');
    }

}

export function* watchGetParamOtType() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_GET_PARAM_OT_TYPE, getParamOtType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* update(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2055/update', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update error',e);
    }

}

export function* watchUpdate() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_UPDATE, update);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);

        const response = yield Api.post('/w29f2055/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add data error');
    }

}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_ADD, add);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* edit(data) {
    try {
        yield delay(300);

        const response = yield Api.put('/w29f2055/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit data error');
    }

}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_EDIT, edit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* cancelData(data) {
    try {
        yield delay(300);

        const response = yield Api.put('/w29f2055/cancel', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('destroy data error');
    }

}

export function* watchCancelData() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_CANCEL, cancelData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getEmployee(data) {
    try {
        yield delay(300);

        const response = yield Api.put('/w29f2055/load-chosen-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit data error');
    }

}

export function* WatchGetEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2055_EMPLOYEE, getEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}