/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/30/2020
 * @Example 
 */

import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../W2X/W29F2011/W29F2011_actions";
import { delay } from "redux-saga";

export default function W29F2011Sagas() {
    return [
        watchGetDataGrid(),
        watchApprovalStatus(),
        watchCancelApproval(),
        watchGetOTTypeName()

    ];
}

//get columns..
export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2011/load-form', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2011_GET_GRID_DATA_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data grid error');
    }

}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2011_GET_GRID_DATA, getDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get approval status..
export function* approvalStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2011/approval', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2011_APPROVAL_STATUS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('approval Status error');
    }

}

export function* watchApprovalStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2011_APPROVAL_STATUS, approvalStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get cancel approval..
export function* cancelApproval(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2011/cancel-approval', data.params);
        if (response && response.data && Number(response.data.Status) === 0 ) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response.data, null)
        }
    }
    catch (e) {
        console.log('cancel Approval error');
    }

}

export function* watchCancelApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2011_CANCEL_APPROVAL, cancelApproval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get get OT Type Name
export function* getOTTypeName(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2011/get-ot-type', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2011_GET_OT_TYPE_NAME_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get get OT Type Name error');
    }

}

export function* watchGetOTTypeName() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2011_GET_OT_TYPE_NAME, getOTTypeName);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

