/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/29/2020
 * @Example
 */

export const types = {
    W29F2000_GET_CBO_SHIFT: "W29F2000_GET_CBO_SHIFT",
    W29F2000_GET_CBO_SHIFT_SUCCESS: "W29F2000_GET_CBO_SHIFT_SUCCESS",
    W29F2000_GET_WORKING_DAY_TYPE: "W29F2000_GET_WORKING_DAY_TYPE",
    W29F2000_GET_WORKING_DAY_TYPE_SUCCESS: "W29F2000_GET_WORKING_DAY_TYPE_SUCCESS",
    W29F2000_GET_DATAGRID: "W29F2000_GET_DATAGRID",
    W29F2000_GET_DATAGRID_SUCCESS: "W29F2000_GET_DATAGRID_SUCCESS",
    W29F2000_SAVE_DATA: "W29F2000_SAVE_DATA",
    W29F2000_GET_CBO_GROUP_EMPLOYEE: "W29F2000_GET_CBO_GROUP_EMPLOYEE",
};

export function getCboShift(params, cb) {
    return {
        type: types.W29F2000_GET_CBO_SHIFT,
        params,
        cb
    };
}

export function getWorkingDayType(cb) {
    return {
        type: types.W29F2000_GET_WORKING_DAY_TYPE,
        cb
    };
}

export function getDataGrid(params, cb) {
    return {
        type: types.W29F2000_GET_DATAGRID,
        params,
        cb
    };
}

export function saveData(params, cb) {
    return {
        type: types.W29F2000_SAVE_DATA,
        params,
        cb
    };
}

export function getCboGroupEmployee(params, cb) {
    return {
        type: types.W29F2000_GET_CBO_GROUP_EMPLOYEE,
        params,
        cb
    };
}




