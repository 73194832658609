/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 8/7/2021
 * @Example
 */

export const types = {
    W29F2017_GET_DATA: 'W29F2017_GET_DATA',
    W29F2017_SAVE: 'W29F2017_SAVE',
};

export function getData(params, cb) {
    return {
        type: types.W29F2017_GET_DATA,
        params,
        cb,
    };
}

export function save(params, cb) {
    return {
        type: types.W29F2017_SAVE,
        params,
        cb,
    };
}
