/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/2/2019
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2005_actions";
import { delay } from "redux-saga";

export default function W29F2005Sagas() {
  return [
    watchGetTimeKeepingList(),
    watchDeleteAttendanceVoucher(),
    watchGetCboHandOver(),
    watchGetCboProjects(),
    watchGetDateByTran()
  ];
}

export function* getTimeKeepingList(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/w29f2005/load-grid", data.params);
    if (res && res.data) {
      yield put({
        type: types.W29F2005_GET_TIMEKEEPINGLIST_SUCCESS,
        data: res.data
      });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {}
}

export function* watchGetTimeKeepingList() {
  while (true) {
    const watcher = yield takeLatest(
      types.W29F2005_GET_TIMEKEEPINGLIST,
      getTimeKeepingList
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* deleteAttendanceVoucher(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/w29f2005/del", data.params);
    console.log();
    if (res && res.data) {
      yield put({
        type: types.W29F2005_DELETE_ATTENDANCEVOUCHER_SUCCESS,
        data: res.data
      });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {}
}

export function* watchDeleteAttendanceVoucher() {
  while (true) {
    const watcher = yield takeLatest(
      types.W29F2005_DELETE_ATTENDANCEVOUCHER,
      deleteAttendanceVoucher
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboHandOver(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w29f2005/get-handover", data.params);
    if (response && response.data) {
      yield put({
        type: types.W29F2005_GET_CBO_HANDOVER_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get cbo handover error");
  }
}

export function* watchGetCboHandOver() {
  while (true) {
    const watcher = yield takeLatest(
      types.W29F2005_GET_CBO_HANDOVER,
      getCboHandOver
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboProjects(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2161/load-cbo-project", data.params);
    if (response && response.data) {
      yield put({
        type: types.W29F2005_GET_CBO_PROJECTS_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response);
    }
  } catch (e) {
    console.log("get cbo projects error");
  }
}

export function* watchGetCboProjects() {
  while (true) {
    const watcher = yield takeLatest(
      types.W29F2005_GET_CBO_PROJECTS,
      getCboProjects
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getDateByTran(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w29f3000/get-date-by-tran", data.params);
    if (response && response.data) {
      yield put({
        type: types.W29F2005_GET_DATE_BY_TRAN_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {}
}

export function* watchGetDateByTran() {
  while (true) {
    const watcher = yield takeLatest(
      types.W29F2005_GET_DATE_BY_TRAN,
      getDateByTran
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
