/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/16/2020
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W38F2000_actions";
import { delay } from "redux-saga";

export default function W38F2000Sagas() {
    return [
        watchGetCboTraining(),
        watchLoadGrid(),
        watchDeleteDataGrid()
    ];
}

export function* getCboTraining(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W38F2000/get-combo-training', data.params);
        if (response && response.data) {
            yield put({
                type: types.W38F2000_GET_COMBO_TRAINING_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchGetCboTraining() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2000_GET_COMBO_TRAINING, getCboTraining);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W38F2000/load-grid', data.params);
        if (response && response.data) {
            yield put({
                type: types.W38F2000_LOAD_DATA_GRID_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2000_LOAD_DATA_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* deleteDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W38F2000/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null);
        }
        else {
            data.cb && data.cb(response);
        }
    }
    catch (e) {
        console.log('Delete grid error');
    }

}

export function* watchDeleteDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W38F2000_DELETE_GRID, deleteDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}