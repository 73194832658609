export const types = {
    GET_CBO_OTT_TYPE: "GET_CBO_OTT_TYPE",
    GET_CBO_OTT_TYPE_SUCCESS: "GET_CBO_OTT_TYPE_SUCCESS",
    GET_FORM:"GET_FORM",
    GET_FORM_SUCCESS: "GET_FORM_SUCCESS",
};

export function getCboOtType(params, cb) {
    return {
        type: types.GET_CBO_OTT_TYPE,
        params,
        cb,
    };
}

export function getForm(params, cb) {
    return {
        type: types.GET_FORM,
        params,
        cb,
    };
}

