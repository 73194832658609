/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 2/24/2021
 * @Example
 */
export const types = {

    W29F2055_GET_CBO_OTTYPE: "W29F2055_GET_CBO_OTTYPE",
    W29F2055_GET_PARAM_OT_TYPE: "W29F2055_GET_PARAM_OT_TYPE",
    W29F2055_GET_CAPTION: "W29F2055_GET_CAPTION",
    W29F2055_GET_CBO_CHOOSE_GROUP_EMPLOYEE: "W29F2055_GET_CBO_CHOOSE_GROUP_EMPLOYEE",
    W29F2055_GET_CBO_GROUP_EMPLOYEE: "W29F2055_GET_CBO_GROUP_EMPLOYEE",
    W29F2055_GET_FORM: "W29F2055_GET_FORM",
    W29F2055_GET_GRID_EMPLOYEE: "W29F2055_GET_GRID_EMPLPOYEE",
    W29F2055_UPDATE: "W29F2055_UPDATE",
    W29F2055_ADD: "W29F2055_ADD",
    W29F2055_EDIT: "W29F2055_EDIT",
    W29F2055_EMPLOYEE: "W29F2055_EMPLOYEE",
    W29F2055_CANCEL: "W29F2055_CANCEL",


};

export function getCboOtType(params, cb) {
    return {
        type: types.W29F2055_GET_CBO_OTTYPE,
        params,
        cb
    }
}

export function getParamOtType(cb) {
    return {
        type: types.W29F2055_GET_PARAM_OT_TYPE,
        cb
    }
}

export function getCaption(cb) {
    return {
        type: types.W29F2055_GET_CAPTION,
        cb
    }
}

export function getCboChooseGroupEmployee(params,cb) {
    return {
        type: types.W29F2055_GET_CBO_CHOOSE_GROUP_EMPLOYEE,
        params,
        cb
    }
}

export function getCboGroupEmployee(params,cb) {
    return {
        type: types.W29F2055_GET_CBO_GROUP_EMPLOYEE,
        params,
        cb
    }
}

export function getForm(params,cb) {
    return {
        type: types.W29F2055_GET_FORM,
        params,
        cb
    }
}

export function getGridEmployee(params,cb) {
    return {
        type: types.W29F2055_GET_GRID_EMPLOYEE,
        params,
        cb
    }
}


export function update(params,cb) {
    return {
        type: types.W29F2055_UPDATE,
        params,
        cb
    }
}

export function add(params, cb) {
    return {
        type: types.W29F2055_ADD,
        params,
        cb
    }
}

export function edit(params, cb) {
    return {
        type: types.W29F2055_EDIT,
        params,
        cb
    }
}

export function cancelData(params, cb) {
    return {
        type: types.W29F2055_CANCEL,
        params,
        cb
    }
}

export function getEmployee(params, cb) {
    return {
        type: types.W29F2055_EMPLOYEE,
        params,
        cb
    }
}
