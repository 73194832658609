/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/23/2019
 * @Example 
 */

import { types } from "./W29F2045_actions";

const initialState = {
    getForms: {},
    getFormsMaster: {},
    saveForms:{},
    editForms:{}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
    
        case types.W29F2045_GET_FORMS_SUCCESS:
            return { ...state, getForms: action.data };
        case types.W29F2045_GET_FORMS_MASTER_SUCCESS:
            return { ...state, getFormsMaster: action.data };
        case types.W29F2045_SAVE_FORMS_SUCCESS:
            return { ...state, saveForms: action.data };
        case types.W29F2045_EDIT_FORMS_SUCCESS:
            return { ...state, editForms: action.data };
        default:
            return state;
    }
}
