/**
 * @copyright 2020 @ DigiNet
 * @author HuyTran
 * @create 10/14/2020
 * @Example
 */
import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2100_actions";
import { delay } from "redux-saga";

export default function W25F2100Sagas() {
    return [
        watchGetNote(),
        watchGetMonthPayroll(),
        watchGetDetailWorkday(),

    ];
}
//get Date By Tran
export function* getNote(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2100/get-note", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("error get-date-by-tran")
    }
}

export function* watchGetNote() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2100_GET_NOTE, getNote
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get Month Payroll
export function* getMonthPayroll(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2100/get-month-payroll", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("error get Month Payroll")
    }
}

export function* watchGetMonthPayroll() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2100_GET_MONTH_PAYROLL, getMonthPayroll
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
//get-detail-workday
export function* getDetailWorkday(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2100/get-detail-workday", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("error get-detail-workday")
    }
}

export function* watchGetDetailWorkday() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2100_GET_DETAIL_WORKDAY, getDetailWorkday
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}