export const types = {
    W29F2016_GET_USE_OTTYPE: "W29F2016_GET_USE_OTTYPE",
    W29F2016_GET_FORM:"W29F2016_GET_FORM",
    W29F2016_APPROVAL: "W29F2016_APPROVAL",
};

export function getUseOtType(cb) {
    return {
        type: types.W29F2016_GET_USE_OTTYPE,
        cb,
    };
}

export function getForm(params, cb) {
    return {
        type: types.W29F2016_GET_FORM,
        params,
        cb,
    };
}

export function approval(params, cb) {
    return {
        type: types.W29F2016_APPROVAL,
        params,
        cb,
    };
}


