/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 2/24/2021
 * @Example 
 */
export const types = {
   
    W29F2056_GET_OT_HOUR: "W29F2056_GET_OT_HOUR",
    W29F2056_SAVE_DATA: "W29F2056_SAVE_DATA",

};

export function getOtHour(params, cb) {
    return {
        type: types.W29F2056_GET_OT_HOUR,
        params,
        cb
    }
}

export function saveData(params, cb) {
    return {
        type: types.W29F2056_SAVE_DATA,
        params,
        cb
    }
}
