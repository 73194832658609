import { types } from "./W29F2012_actions";

const initialState = {
    getForm: [],
    getCboOtType: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.GET_CBO_OTT_TYPE_SUCCESS:
            return {
                ...state,
                getCboOtType: action.data
            };
        case types.GET_FORM_SUCCESS:
            return {
                ...state,
                getForm: action.data
            };
        default:
            return state;
    }
}