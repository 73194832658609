export const types = {
    W29F3000_GET_CBO_HANDOVER: 'W29F3000_GET_CBO_HANDOVER',
    W29F3000_GET_CBO_PERIOD: 'W29F3000_GET_CBO_PERIOD',
    W29F3000_GET_CBO_REPORTS: 'W29F3000_GET_CBO_REPORTS',
    W29F3000_GET_DATE_DATA: 'W29F3000_GET_DATE_DATA',
};

export function getCboReports(params, cb) {
    return {
        type: types.W29F3000_GET_CBO_REPORTS,
        params,
        cb
    }
}

export function getCboHandOver(params, cb) {
    return {
        type: types.W29F3000_GET_CBO_HANDOVER,
        params,
        cb
    }
}

export function getCboPeriod(params, cb) {
    return {
        type: types.W29F3000_GET_CBO_PERIOD,
        params,
        cb
    }
}

export function getDateData(params, cb) {
    return {
        type: types.W29F3000_GET_DATE_DATA,
        params,
        cb
    }
}


