/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 06/30/2020
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W39F1000_actions";
import { delay } from "redux-saga";

export default function W39F1000Sagas() {
    return [
        watchGetGrid(),
        watchDeleteDataGrid()
    ];
}

export function* getGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1000/load-form-grid', data.params);
        if (response && response.data) {
            yield put({
                type: types.W39F1000_LOAD_GRID_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('get grid error');
    }

}

export function* watchGetGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1000_LOAD_GRID, getGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* deleteDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1000/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null);
        }
        else {
            data.cb && data.cb(response);
        }
    }
    catch (e) {
        console.log('Delete grid error');
    }

}

export function* watchDeleteDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1000_DELETE_GRID, deleteDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}