/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 06/30/2020
 * @Example
 */

import { types } from "./W39F1000_actions";

const initialState = {
    dataGrid: []
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F1000_LOAD_GRID_SUCCESS: {
            return {
                ...state,
                dataGrid: action.data
            };
        }
        default:
            return state;
    }
}
