/**
 * @Copyright 2021 @DigiNet
 * @Author QUOCUY
 * @Create 4/29/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from "../../../services/api";
import { types } from "./W29F2070_actions";
import Config from "../../../config";

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return (filename = decodeURIComponent(reFileName));
    }
    return filename; // else return original data
}

export default function W39F1203Sagas() {
    return [watchLoadGrid(), watchDeleteRow(), watchExportData(), watchGetIsExport()];
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2070/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2070_LOAD_GRID, loadGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w29f2050/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2070_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getIsExport(data) {
    try {
        yield delay(300);

        const response = yield Api.get("/w29f2050/is-export");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get IsExport error");
    }
}

export function* watchGetIsExport() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2070_GET_ISEXPORT, getIsExport);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* exportData(data) {
    try {
        yield delay(300);
        let response = yield Api.putExport("/w29f2050/export", data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null);
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + ".xls";
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("export excel template error");
    }
}

export function* watchExportData() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2070_EXPORT, exportData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
