/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/20/2019
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2040_actions";
import { delay } from "redux-saga";

export default function W29F2040Sagas() {
    return [
        watchGetForms(),
        watchGetCboOrgCharts(),
        watchGetCboShift(),
        watchDeleteActOTHour(),
    ];
}



export function* getForms(data) {
    try {
        yield delay(300);
        const response = yield Api.put('//w29f2040/load-form',data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2040_GET_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('call api error');
    }

}
export function* watchGetForms() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2040_GET_FORM, getForms);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
export function* getCboOrgCharts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2005/load-data-org-chart',data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2040_GET_CBO_ORG_CHARTS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('call api error');
    }

}
export function* watchGetCboOrgCharts() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2040_GET_CBO_ORG_CHARTS, getCboOrgCharts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboShifts(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2030/load-shift');
        if (response && response.data) {
            yield put({ type: types.W29F2040_GET_CBO_SHIFTS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('call api error');
    }

}
export function* watchGetCboShift() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2040_GET_CBO_SHIFTS, getCboShifts);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
export function* deleteActOTHour(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2040/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('deleteActOTHour error');
    }

}

export function* watchDeleteActOTHour() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2040_DELETE_ACT_OT_HOUR, deleteActOTHour);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}



