/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/16/2020
 * @Example
 */

import { types } from "./W38F2001_actions";

const initialState = {
    cboTrainingField: [],
    dataForm: null,
    dataGrid: [],
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.W38F2001_LOAD_TRAINING_FIELD_SUCCESS: {
            return {
                ...state,
                cboTrainingField: action.data
            };
        }
        case types.W38F2001_LOAD_TRAINING_COURSE_DETAIL_SUCCESS: {
            return {
                ...state,
                dataForm: {
                    ...state.dataForm,
                    ...action.data
                }
            };
        }
        case types.W38F2001_LOAD_FORM_SUCCESS: {
            return {
                ...state,
                dataForm: action.data
            };
        }
        case types.W38F2001_LOAD_GRID_SUCCESS: {
            const oldData = state.dataGrid;
            action.data.forEach(e => {
                const index = oldData.findIndex(d => d.EmployeeID === e.EmployeeID);
                if (index < 0) {
                    oldData.push(e);
                }
            })
            return {
                ...state,
                dataGrid: [ ...oldData ]
            };
        }
        case types.W38F2001_RESET_PROPS: {
            return {
                ...state,
                dataForm: action.DepartmentID ? {DepartmentID: action.DepartmentID} : null,
                dataGrid: []
            };
        }
        case types.W38F2001_REMOVE_ITEM_SUCCESS: {
            return {
                ...state,
                dataGrid: state.dataGrid.filter(e => e.EmployeeID !== action.data)
            };
        }
        default:
            return state;
    }
}
