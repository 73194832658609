/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 2/24/2021
 * @Example
 */
export const types = {
   
    W29F2050_GET_DATA_GRID: "W29F2050_GET_DATA_GRID",
    W29F2050_DELETE_DATA_GRID: "W29F2050_DELETE_DATA_GRID",
    W29F2050_EXPORT: "W29F2050_EXPORT",
    W29F2050_GET_ISEXPORT: "W29F2050_GET_ISEXPORT",

};

export function getDataGrid(params, cb) {
    return {
        type: types.W29F2050_GET_DATA_GRID,
        params,
        cb
    }
}

export function deleteDataGrid(params, cb) {
    return {
        type: types.W29F2050_DELETE_DATA_GRID,
        params,
        cb
    }
}

export function exportData(params, cb) {
    return {
        type: types.W29F2050_EXPORT,
        params,
        cb
    }
}

export function getIsExport(cb) {
    return {
        type: types.W29F2050_GET_ISEXPORT,
        cb
    }
}
