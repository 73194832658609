/**
 * @copyright 2021 @ DigiNet
 * @author TRANGHOANG
 * @create 4/29/2021
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest, takeEvery } from "redux-saga/effects";
import { types } from "./W29F2072_actions";
import { delay } from "redux-saga";

export default function W29F2072Sagas() {
    return [
        watchGetCboOtType(),
        watchGetParamOtType(),
        watchGetCaptionMaster(),
        watchGetCboChooseGroupEmployee(),
        watchGetCboGroupEmployee(),
        watchGetForm(),
        watchAdd(),
        watchEdit(),
        watchGetEmployee(),
        watchLoadGridSetting(),
        watchLoadGroupEmployVisible(),
        watchGetGridCaption(),
        watchLoadGroupEmployee(),
        watchCancelEmployee(),
    ];
}

export function* getCboOtType(data) {
    try {
        yield delay(300);

        const response = yield Api.put('/w29f2012/load-ottype', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get OtType error');
    }

}

export function* watchGetCboOtType() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_GET_CBO_OTTYPE, getCboOtType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getGridCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2072/load-grid-ref-caption');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get caption error');
    }

}

export function* watchGetGridCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_GET_GRID_CAPTION, getGridCaption);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCaptionMaster(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2072/load-master-ref-caption');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get caption error');
    }

}

export function* watchGetCaptionMaster() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_GET_CAPTION_MASTER, getCaptionMaster);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadGridSetting(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2072/load-grid-setting');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get caption error');
    }

}

export function* watchLoadGridSetting() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_LOAD_GRID_SETTING, loadGridSetting);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadGroupEmployVisible(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2072/load-group-employee-button-visible');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get caption error');
    }

}

export function* watchLoadGroupEmployVisible() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_LOAD_GROUP_EMPLOY_VISIBLE, loadGroupEmployVisible);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboChooseGroupEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2072/Load-chosen-group-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Cbo Choose Group Employee error');
    }

}

export function* watchGetCboChooseGroupEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_GET_CBO_CHOOSE_GROUP_EMPLOYEE, getCboChooseGroupEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboGroupEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-group-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Cbo Group Employee error');
    }

}

export function* watchGetCboGroupEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_GET_CBO_GROUP_EMPLOYEE, getCboGroupEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2072/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form error');
    }

}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeEvery(types.W29F2072_GET_FORM, getForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getParamOtType(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2072/get-ot-type');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Param OtType error');
    }

}

export function* watchGetParamOtType() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_GET_PARAM_OT_TYPE, getParamOtType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);

        const response = yield Api.post('/W29F2072/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add data error');
    }

}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_ADD, add);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* edit(data) {
    try {
        yield delay(300);

        const response = yield Api.put('/W29F2072/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit data error');
    }

}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_EDIT, edit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getEmployee(data) {
    try {
        yield delay(300);

        const response = yield Api.put('/W29F2072/Load-chosen-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get employee error');
    }

}

export function* watchGetEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_EMPLOYEE, getEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadGroupEmployee(data) {
    try {
        yield delay(300);

        const response = yield Api.put('standard/load-cbo-group-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load group employee error');
    }

}

export function* watchLoadGroupEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_LOAD_GROUP_EMPLOYEE, loadGroupEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* cancelEmployee(data) {
    try {
        yield delay(300);

        const response = yield Api.put('/w29f2072/cancel-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit data error');
    }

}

export function* watchCancelEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2072_CANCEL_EMPLOYEE, cancelEmployee);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
